<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core';
import type { InputAttributes } from '~ui/types/inputAttributes';
import type { TextInputTypes } from '~ui/types/textInputTypes';
import type { ValidationProp } from '~ui/types/validationProp';

interface Props extends InputAttributes {
  type?: TextInputTypes;
  placeholder: string;
  errorMessages?: string[];
  rules: ValidationProp;
  disabled?: boolean;
  readonly?: boolean;
  autocomplete?: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  autocomplete: undefined,
  errorMessages: () => [],
});

const value = defineModel<string | number>({ required: true });

const validationKey = useId();

const validation = useVuelidate(
  {
    [validationKey]: props.rules,
  },
  {
    [validationKey]: value,
  },
);

const _errorMessages = computed(() => {
  const errors = validation.value.$errors.map((e) => unref(e.$message));
  return [...props.errorMessages, ...errors];
});

const onBlur = () => {
  validation.value.$touch();
};
</script>

<template>
  <UIInputText
    v-model="value"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :errorMessages="_errorMessages"
    :hideLabel="hideLabel"
    :label="label"
    :placeholder="placeholder"
    :readonly="readonly"
    :required="required"
    :type="type"
    @blur="onBlur"
  />
</template>
